.menu-category {
    padding: 0px 16px;
    line-height: 48px;
    min-height: 48px;
    color: #2a3f54;
    font-weight: bold;
    font-size: 15px;
}

.menu-category:not(:first-of-type) {
    margin-top: 20px;
}
