.gallery {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-auto-columns: max-content;
    grid-template-rows: auto;
    gap: 1rem;
    padding: 1rem;
}

.photo {
    position: relative;
    width: 15rem;
    height: 15rem;
    border: 1px solid #e2e8f0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
}

.photo button {
    display: none;
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    background-color: #ff4f6e;
    color: white;
    padding: 0.2rem 1rem;
    border-radius: 4px;
}

.photo:hover button {
    display: block;
}

.gallery img {
    object-fit: contain;
    height: 12rem;
    width: 12rem;
    cursor: pointer;
}
