table {
    border-spacing: 0;
}

th.jsonColumn {
    padding: 2px 10px;
}

td.jsonCell {
    padding: 2px 10px;
    max-width: 400px;
    word-wrap: break-word;
}
