.addPhoto {
    display: block !important;
    font-size: 1.1em;
    margin: 1em auto !important;
    width: 120px;
}

.addPhoto:hover,
.addPhoto:focus {
    /* //color: #fff !important; */
}

.addPhotoInput {
    opacity: 0;
    position: absolute;
    top: 1em;
    text-align: center;
    width: 200px;
    height: 50px;
    cursor: pointer;
}

.deletePhoto {
    position: absolute;
    top: 0;
    right: 5px;
    margin: 0 !important;
    padding: 0 !important;
    border-radius: 50% !important;
}

.deletePhoto i {
    color: #fff;
    padding: 3px 5px !important;
    margin: 0;
}

.pending {
    font-size: '20px';
}
