.form {
    :global(.RaToolbar-desktopToolbar-407),
    :global(.MuiToolbar-gutters) {
        display: none;
    }
}

.tags {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    &_new {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &_check {
        margin-left: 1rem;
        margin-right: 1rem;
    }
}
