.role div p {
    display: none !important;
}

.role div label {
    color: #8c8c8c !important;
}

.role div fieldset {
    border-color: rgba(0, 0, 0, 0.26) !important;
    border-width: 1px !important;
}
