.popup_container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100000;
}

.popup_back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgb(0, 0, 0, 0.3);
}

.popup {
    padding: 1rem;
    border-radius: 4px;
    background-color: #fff;
    z-index: 10000000;
}
