.charge {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 1rem;
}

.title {
    width: 100%;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
}

.input {
    width: 100%;
    margin-bottom: 2rem;
}

.actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
}

.btn {
    flex: 1;
    padding: 0.3rem 0;
    color: white;
    border-radius: 4px;
}

.btn_cancel {
    background-color: gray;
}

.btn_charge {
    background-color: #09c2f3;
}

.btn_charge:disabled {
    background-color: #afdef3;
    cursor: default;
}
