.uoloadData {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
}

.uploadDataInfo {
}

.uploadProgressData {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.uploadProgress {
    width: 10rem;
    height: 0.5rem;
    background-color: #b5efff;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
}

.uploadProgress div {
    background-color: #08c2f3;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    height: 0.5rem;
}
