.dropdownlist {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    max-width: 14rem;
}

.button {
    background-color: #fff;
    padding: 18.5px 14px;
    padding-top: 10.5px;
    padding-bottom: 10.5px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid rgb(203, 203, 203);
    cursor: pointer;
    display: inline-flex;
    position: relative;
    font-size: 1rem;
    box-sizing: border-box;
    align-items: center;
}

.button h3 {
    font-family: Poppins, sans-serif;
    font-weight: 400;
    line-height: 1.1876em;
    color: #515b70;
}

.list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 15rem;
    overflow-y: scroll;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 100000;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
        0px 8px 10px 1px rgba(0, 0, 0, 0.14),
        0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    background-color: white;
}

.list_item {
    width: 100%;
    cursor: pointer;
    padding: 0.5rem 1rem;
}

.list_item:hover {
    background-color: rgb(232, 232, 232);
}
