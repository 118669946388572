.configsCard {
    padding: 1rem;
}

.conutriesContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.country_btn {
    background-color: #0BC2F3;
    padding: .5rem 1rem;
    border-radius: 4px;
    color: white;
}