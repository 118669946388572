.pending {
    background: rgb(231, 207, 207) !important;
    border: 1px solid red;
    color: '#595959';
}
/* .order_new,
.request_new,
.storage_new,
.box_new {
    background: #e6e6fa !important;
    color: purple !important;
    border: 1px solid #80008040 !important;
} */
.order_new,
.task_new,
.user_new {
    background: #e6e6fa !important;
    color: purple !important;
    border: 1px solid #80008040 !important;
}
.order_quote_accepted,
.box_empty {
    background: #e6e6fa !important;
    color: blue !important;
    border: 1px solid blue !important;
}
.order_quote_sent,
.request_confirmed,
.storage_pickedup,
.invoice_partial {
    background: #fffbe6 !important;
    border: 1px solid orange;
    color: orange !important;
}
.storage_in,
.payment_refunded,
.storage_record_in,
.task_paused {
    background: #00808017 !important;
    border: 1px solid teal;
    color: teal !important;
}
.order_confirmed,
.order_active,
.user_active,
.request_approved,
.storage_stored,
.box_assigned,
.invoice_paid,
.payment_complete,
.storage_record_active,
.task_running,
.task_complete {
    background: #f6ffed !important;
    border: 1px solid green;
    color: green !important;
}
.order_onhold,
.request_onhold {
    background: lightgrey !important;
    border: 1px solid brown !important;
    color: brown !important;
}
.request_transit,
.request_received,
.request_delivered {
    background: #f6ffed !important;
    border: 1px solid cyan;
    color: cyan !important;
}
.order_cancelled,
.request_cancelled,
.request_rejected,
.user_rejected,
.payment_pending {
    background: #f5f5f5 !important;
    border: 1px solid grey;
    color: grey !important;
}
.order_rejected,
.order_inactive,
.user_inactive {
    background: #f5f5f5 !important;
    border: 1px solid grey;
    color: grey !important;
}
.order_complete,
.request_complete {
    background: #e6f7ff !important;
    border: 1px solid #3174ad;
    color: #3174ad !important;
}
.order_lost,
.order_quote_rejected,
.order_suspended,
.user_suspended,
.storage_unknown,
.storage_damaged,
.storage_lost,
.storage_missing,
.storage_not_returned,
.box_damaged,
.box_unknown,
.box_other,
.box_lost,
.box_missing,
.box_not_returned,
.invoice_unpaid,
.payment_failed,
.task_failed {
    background: #fff2e8 !important;
    border: 1px solid #ffbb96;
    color: #fa541c !important;
}
